footer{
    /* background: var(--color-primary); */
    /* background-color: rgba(192, 125, 17, 0.56); */
    background-color: transparent;
    padding: 3rem 0;
    text-align: center;
    font-size: 0.9rem;
    margin-top: 7rem;
    color: white;
}

footer a{
    /* color:var(--color-bg);
     */
     color:var(--color-primary);
}


.footer__logo{
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 2rem;
    display:inline-block;

}
.permalinks{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    margin: 0 auto 3rem;

}

.footer__socials{
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 3rem;
}

.footer__socials a{
    background: var(--color-bg);
    color: var(--color-white);
    padding: 0.8rem;
    border-radius: 0.7rem;
    display: flex;
    border: 1px solid transparent;
}

.footer__socials a:hover{
    background: transparent;
    /* color: var(--color-bg); */
    color: var(--color-primary);
    /* border-color: var(--color-bg) ; */
    border-color: white;

}

.footer__copyright{
    display: flex;
    gap: 1.5rem;
    flex-direction: column;
    margin-bottom:4rem;
    /* color: var(--color-bg); */
    color: white;
}

.photography a{
    text-decoration: underline;
    font-size: 0.9rem;
}

/* ================= media queries (small devices) ============== */

@media screen and (max-width: 600px) {
.permalinks{
    flex-direction: column;
    gap: 1.5rem;
}
.footer__socials{
    margin-bottom: 1.5rem;
}

}