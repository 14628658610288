.portfolio__container{
    /* display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 2.5rem; */
    /* height: 100vh; */
   



}
.swiper-wrapper{
  margin-left: 1rem;
}


.portfolio__item{
    background: var(--color-bg-varient);
    padding: 1.2rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
    /* box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px; */
    /* box-shadow: rgba(192, 125, 17, 0.56) 0px 22px 70px 4px; */
    height: fit-content;
    width:350px;


}

.portfolio__item:hover{
    border-color: var(--color-primary-varient);
    background: transparent;
}

.portfolio__item-image{
    border-radius: 1.5rem;
    overflow: hidden;
}

.portfolio__item h3{
    margin: 1.2rem 0 2rem;
}

.portfolio__item-cta{
    display: flex;
    align-content: center;
    gap: 1rem;
    margin-bottom: 1rem;
    
    

}

.projectImage:hover{

    

}
.flip-box{

    width: 300px;
    height: 200px;
    perspective: 1000px;
    background-color: transparent;
 
 
}
/* This container is needed to position the front and back side */
.flip-box-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.9s;
    transform-style: preserve-3d;
  }
  
  /* Do an horizontal flip when you move the mouse over the flip box container */
  .flip-box:hover .flip-box-inner {
    transform: rotateY(180deg);
  }
  
  /* Position the front and back side */
  .flip-box-front, .flip-box-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
  }
  
  /* Style the front side (fallback if image is missing) */
  .flip-box-front {
    background-color: #bbb;
    color: black;
   
  }
  
  /* Style the back side */
  .flip-box-back {
    background-color: #ffa31a;
    color: #000;
    transform: rotateY(180deg);
    border-radius: 1.5rem;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }

/* ================= media queries (medium devices) ============== */

@media screen and (max-width: 1024px) {
   .portfolio__container{
    grid-template-columns: 1fr 1fr;
    gap: 1.2rem;
   }
}
/* ================= media queries (small devices) ============== */

@media screen and (max-width: 600px) {
        .portfolio__container{
            grid-template-columns: 1fr;
            gap: 1rem;
        }
}

@media screen and (min-width: 650px) {

    .portfolio__container{
        
        height:100vh;
    }
    
    }