.container.testimonials__container{
        width:40%;
        padding-bottom: 4rem;
       
        height: fit-content;
      
       

   
       

}




.client__avatar{
    width:4rem;
    aspect-ratio: 1/1;
    overflow: hidden;
    border-radius: 50%;
    margin:0 auto 1rem;
    border: 0.4rem solid var(--color-primary-varient);
}

.testimonial{
    background: var(--color-bg-varient);
    text-align: center;
    padding: 2rem;
    border-radius: 2rem;
    user-select: none;
    /* box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px; */
    /* box-shadow: rgba(192, 125, 17, 0.56) 0px 22px 70px 4px; */

    
    
}
.testimonial:hover{
    background-color: transparent;
    border-color: var(--color-primary-varient);
}

.client__review{
    color: var(--color-light);
    font-weight: 300;
    display: block;
    width: 80%;
    margin:0.8rem auto 0 ;
}
.swiper-pagination-clickable .swiper-pagination-bullet{
    background: var(--color-primary) ;
}

@media screen and (min-width: 1025px) {
    #testimonial{
        margin-top: 20rem;
    }
}

/* ================= media queries (medium devices) ============== */


@media screen and (max-width: 1024px) {
  .container.testimonials__container{
    width: 60%;
  }
    }

/* ================= media queries (small devices) ============== */

@media screen and (max-width: 600px) {
    .container.testimonials__container{
        width: var(--container-width-sm);

    }
    .client__review{
        width:var(--container-width-sm);
    }

}
