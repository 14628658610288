header{
    height: 100vh;
    padding-top: 2rem;
    overflow: hidden;
    display:flex;
    flex-direction: row;
    align-items: center;
}
/* section{
    height: 100vh;
} */




.header__container{
    text-align: center;
    height: 100%;
    position: relative;
} 

/* ======== CTA =========== */
.cta{
    margin-top:.5rem ;
    display: flex;
    gap: 1.2rem; 
    justify-content: center;
}

/* ====== header socials ========== */

.header_social{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 30rem;
}

.header_social::after{
    content: '';
    width: 1px;
    height: 2rem;
    background-color: var(--color-primary);
}

/* ============ img me ============== */

.me{
    background: linear-gradient(var(--color-primary),transparent);
    width: 20rem;
    height: 30rem;
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 4rem;
    border-radius: 12rem 12rem 0 0;
   overflow: hidden;
   padding: 5rem 1.5rem 1.5rem 1.5rem ;

}

/* ========== scroll down ==== */

.scroll_down{
     position: absolute;
     right:-2.3rem;
     bottom: 35rem;
     transform: rotate(90deg);
     font-weight: 300;
     font-size: 0.9rem;
}

 /* ========== shadow style ================= */
 .shadow {
    display: grid;
  place-items: center;
    color: #fff;
      /* font-family: Arial, Helvetica, sans-serif; */
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 800;
    position: relative;
    margin: 1rem  auto 0 ;
    width: 400px;
    height: 285px;
    background: #15202B;
  }
  
  .shadow::before,
  .shadow::after {
    content: '';
    position: absolute;
    top: -4px;
    left: -4px;
    background: linear-gradient(45deg,red,blue,green,yellow,#e11d74,black,#ffff00,#aa0000);
    background-size: 400%;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    z-index: -1;
    animation: animate 25s linear infinite;
  }
  
  .shadow::after {
    filter: blur(25px);
  }
  
  @keyframes animate {
    0% {
      background-position: 0 0;
    }
  
    50% {
      background-position: 400% 0;
    }
  
    100% {
      background-position: 0 0;
    }
  }

/* ================= media queries (medium devices) ============== */

@media screen and (max-width: 1024px) {
        header{
            height: 68vh;
        }
}
/* ================= media queries (small devices) ============== */

@media screen and (max-width: 600px) {
    header{
        height: 110vh;
    }
    .header_social , .scroll_down{
        display:none ;
    }
    
}




