.experience__container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    /* height: 100vh; */
}
.experience__container > div{
    background: var(--color-bg-varient);
    padding: 2.4rem 5rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
    /* box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px; */
    box-shadow: rgba(192, 125, 17, 0.56) 0px 22px 70px 4px;
    height:fit-content;
}
.experience__container > div:hover{
    background: transparent;
    border-color: var(--color-primary-varient);
    cursor: default;
    transition: var(--transition);
}

.experience__container > div h3{
    margin-bottom: 2rem;
    text-align: center;
    color: var(--color-primary);
}
.experience__content{
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 2rem;
}
.experience__details{
    display: flex;
    gap: 1rem;
}
/* ================= media queries (medium devices) ============== */

@media screen and (max-width: 1024px) {
    .experience__container{
        grid-template-columns: 1fr;

    }
    .experience__container > div{
        width: 80%;
        padding: 2rem;
        margin: 0 auto;
    }
    .experience__content{
        padding: 1rem;
    }
}
/* ================= media queries (small devices) ============== */

@media screen and (max-width: 600px) {
    .experience__container{
        gap: 1rem;
    }
    .experience__container >div{
        width: 100%;
        padding: 2rem 1rem;
    }

    /* section{
       height: 120%;
        
    } */

}

@media screen and (min-width: 650px) {

    .experience__container{
        
        height:100vh;
    }
    
    }