.about__container{
    display: grid;
    grid-template-columns: 35% 50%;
    gap: 15%;
    /* height: 100vh; */
}
.about__me{
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 2rem;
    background: linear-gradient(45deg, transparent ,var(--color-primary),transparent);
    display: grid;
    place-items: center;
}

.about__me-image{
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 2rem;
    overflow:hidden;
    transform: rotate(10deg);
    transition: var(--transition);
}

.about__me-image:hover{
    transform: rotate(0);
}

.about__cards{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 1.5rem;
}
.about__card{
    background: var(--color-bg-varient);
    border: 1px solid transparent;
    border-radius: 1rem;
    padding: 2rem;
    text-align: center;
    transition: var(--transition);
    /* box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px; */
    /* box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
     */
     /* box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset; */
     /* box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
      */
     box-shadow: rgba(242, 152, 7, 0.56) 0px 22px 70px 4px;

}

.about__card:hover{
    background: transparent;
    border-color:var(--color-primary-varient);
    cursor: default;
}

.about__icon{
    color:var(--color-primary);
    font-size: 1.4rem;
    margin-bottom:1rem ;
}
.about__card h5{
    font-size: 0.95rem;
}

.about__card small{
    font-size: 0.7rem;
    color: var(--color-light);
}

.about__content p{
    margin: 2rem 0 2.6rem;
    color:var(--color-light);
}
.condown{
    display: flex;
    flex-direction: row;
    gap: 1.2rem; 
    transition: var(--transition);
    /* justify-content: center; */
}
.condown .btn{
    transition: var(--transition);

}



/* ================= media queries (medium devices) ============== */

@media screen and (max-width: 1024px) {
    .about__container{
        grid-template-columns: 1fr;
        gap: 0;
    }
    .about__me{
        width: 50%;
        margin:2rem auto 4rem;
    }
    .about__content p{
        margin:1rem 0 1.5rem;
    }
    }

/* ================= media queries (small devices) ============== */

@media screen and (max-width: 600px) {

.about__me{
    width: 65%;
    margin:0 auto 3rem;
}
.about__cards{
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
}
.about__content{
    text-align: center;
}
.about__content p{
    margin: 1.5rem 0;
}


}

@media screen and (min-width: 650px) {

.about__container{
    
    height:100vh;
}

}
